module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{"style":{"overlay":{"backgroundColor":"rgba(103, 99, 99, 0.75)"},"content":{"position":"relative","top":"auto","left":"auto","right":"auto","bottom":"auto","maxWidth":"960px","minHeight":"500px","margin":"32px auto","padding":0,"border":"none","backgroundColor":"#F8F8FA"}},"contentLabel":"Modal"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
