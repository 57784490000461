// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-environments-js": () => import("./../../../src/pages/app/environments.js" /* webpackChunkName: "component---src-pages-app-environments-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-config-option-js": () => import("./../../../src/pages/config/option.js" /* webpackChunkName: "component---src-pages-config-option-js" */),
  "component---src-pages-config-option-manager-js": () => import("./../../../src/pages/config/option-manager.js" /* webpackChunkName: "component---src-pages-config-option-manager-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-license-js": () => import("./../../../src/pages/license.js" /* webpackChunkName: "component---src-pages-license-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-nps-js": () => import("./../../../src/pages/nps.js" /* webpackChunkName: "component---src-pages-nps-js" */),
  "component---src-pages-organization-create-js": () => import("./../../../src/pages/organization/create.js" /* webpackChunkName: "component---src-pages-organization-create-js" */),
  "component---src-pages-organization-index-js": () => import("./../../../src/pages/organization/index.js" /* webpackChunkName: "component---src-pages-organization-index-js" */),
  "component---src-pages-organization-update-js": () => import("./../../../src/pages/organization/update.js" /* webpackChunkName: "component---src-pages-organization-update-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-recovery-confirm-js": () => import("./../../../src/pages/recovery/confirm.js" /* webpackChunkName: "component---src-pages-recovery-confirm-js" */),
  "component---src-pages-recovery-request-js": () => import("./../../../src/pages/recovery/request.js" /* webpackChunkName: "component---src-pages-recovery-request-js" */),
  "component---src-pages-statement-create-js": () => import("./../../../src/pages/statement/create.js" /* webpackChunkName: "component---src-pages-statement-create-js" */),
  "component---src-pages-statement-index-js": () => import("./../../../src/pages/statement/index.js" /* webpackChunkName: "component---src-pages-statement-index-js" */),
  "component---src-pages-statement-points-js": () => import("./../../../src/pages/statement/points.js" /* webpackChunkName: "component---src-pages-statement-points-js" */),
  "component---src-pages-statement-view-js": () => import("./../../../src/pages/statement/view.js" /* webpackChunkName: "component---src-pages-statement-view-js" */),
  "component---src-pages-user-create-js": () => import("./../../../src/pages/user/create.js" /* webpackChunkName: "component---src-pages-user-create-js" */),
  "component---src-pages-user-index-js": () => import("./../../../src/pages/user/index.js" /* webpackChunkName: "component---src-pages-user-index-js" */),
  "component---src-pages-user-update-js": () => import("./../../../src/pages/user/update.js" /* webpackChunkName: "component---src-pages-user-update-js" */),
  "component---src-pages-workflow-activities-js": () => import("./../../../src/pages/workflow/activities.js" /* webpackChunkName: "component---src-pages-workflow-activities-js" */),
  "component---src-pages-workflow-agenda-js": () => import("./../../../src/pages/workflow/agenda.js" /* webpackChunkName: "component---src-pages-workflow-agenda-js" */),
  "component---src-pages-workflow-board-js": () => import("./../../../src/pages/workflow/board.js" /* webpackChunkName: "component---src-pages-workflow-board-js" */),
  "component---src-pages-workflow-card-js": () => import("./../../../src/pages/workflow/card.js" /* webpackChunkName: "component---src-pages-workflow-card-js" */)
}

